





















































import { Component, Vue, Watch } from "vue-property-decorator";
import TextComponent from "@/components/TextComponent.vue";
import { investorRelations } from "@/interfaces";
import axios from "axios";
import Files from "@/components/InvestorRelations/Files.vue";

@Component({
  name: "InvestorRelations",
  components: { Files, TextComponent }
})
export default class InvestorRelations extends Vue {
  currentElement: investorRelations | null = null;
  dropdownOpen = false;

  mounted() {
    if (!this.investorRelations || this.investorRelations.length < 1)
      this.fetchData();
    else this.updateInvestorRelationsPagesList();
  }

  fetchData() {
    return axios
      .get(this.$store.state.apiUrl + "/investor-relations?_sort=order")
      .then(response => {
        if (response.status === 200) {
          this.$store.commit("setInvestorRelations", response.data);
        } else console.log(response.statusText);
      });
  }

  get investorRelations() {
    return (
      this.$store.state.investorRelations?.filter(
        (x: investorRelations) => x.language.language === this.lang
      ) || []
    );
  }

  get lang() {
    return this.$store.state.language;
  }

  openSection(elem: investorRelations) {
    this.currentElement = elem;
    this.dropdownOpen = false;
  }

  @Watch("lang")
  langChanged() {
    this.fetchData();
  }

  @Watch("investorRelations")
  updateInvestorRelationsPagesList() {
    if (this.$route.params.entry) {
      this.currentElement =
        this.investorRelations &&
        (this.investorRelations.find(
          (x: investorRelations) => x.siteId === this.$route.params.entry
        ) ||
          this.investorRelations[0]);
    } else
      this.currentElement =
        this.investorRelations && this.investorRelations[0];
  }

  @Watch("currentElement")
  updatePageName() {
    this.$store.commit(
      "setSiteTitle",
      `Dla inwestorów ${(this.currentElement &&
        " - " + this.currentElement.title) ||
        ""}`
    );
  }
}
